module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Magnet Lounge","short_name":"Magnet Lounge","start_url":"/?utm_source=standalone","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"73e45f21cb42b753141e65ac982e1bf6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
