import React from 'react';

import LocaleProvider  from './src/containers/LocaleProvider';

const wrapPageElement = ({ element, props }) => {
  // props provide same data to LocaleProvider as Page element will get
  // including pageContext with locale and translations
  return <LocaleProvider {...props}>{element}</LocaleProvider>;
};

export default wrapPageElement;
