exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-menu-children-js": () => import("./../../../src/templates/MenuChildren.js" /* webpackChunkName: "component---src-templates-menu-children-js" */),
  "component---src-templates-menu-js": () => import("./../../../src/templates/Menu.js" /* webpackChunkName: "component---src-templates-menu-js" */)
}

